//import { loadCategoryById } from "@storefront/core/data-resolver/categories";
import {
  getProductFiltersBySearch,
  getProductBySearch,
  getProductByPartfinder,
  getProductFiltersByPartfinder,
} from "@storefront/core/data-resolver/products";

import store from "@/store";
import { Logger } from "@storefront/core/lib/logger";
//import { router } from "@/main";
import { isServer } from "@storefront/core/helpers";
const state = () => ({
  current: null,
  menuCategories: [],
  productFilters: [],
  activeFilter: [],
  priceFilter: [0, 0],
  filteredCount: 0,
  products: [],
  ShowProducts: [],
  sorting: "default",
  loadProducts: false,
  isBusy: false,
});

const actions = {
  /**
   * Load products by search string
   *
   *
   * @param {object} string search, integer totalPages
   */
  async loadProducts({ commit, getters }, { search, totalPages }) {
    commit("setLoadProducts", true);
    for (let page = 2; page < totalPages + 1; page++) {
      const retv = await getProductBySearch(search, page);
      const loadProd = getters["getLoadProducts"];
      if (loadProd) {
        commit("addProducts", retv.items);
      } else {
        break;
      }
    }
    commit("setIsBusy", false);
  },
  /**
   * load product filters based on id of the category
   *
   * @param {object} string search
   */

  async loadFilters({ commit }, { search = "" }) {
    const filters = await getProductFiltersBySearch(search).catch((e) => {
      Logger.error(
        "getProductFiltersBySearch",
        "category store actions load",
        e
      )();
      throw e;
    });
    const uFilters = [];
    filters.aggregations.forEach((element) => {
      if (element.attribute_code != "category_id") {
        if (element.attribute_code == "price") {
          element.min = parseInt(element.min_value);

          element.max = Math.ceil(element.max_value);

          commit("setPriceFilter", [element.min, element.max]);
        }
        uFilters.push(element);
      }
    });
    commit("setProductFilters", uFilters);
  },

  /**
   * load the search
   *
   * @param {object} string search
   * @returns search object
   */
  async load({ commit, dispatch }, { search = null }) {
    commit("setIsBusy", true);
    if (search == null) {
      commit("setProductFilters", {});
      commit("resetActiveFilter");
      commit("setPriceFilter", [0, 0]);

      return false;
    } else {
      commit("setProducts", []);
      dispatch("loadFilters", { search: search });

      const retval = await getProductBySearch(search, 1);
      commit("addProducts", retval.items);
      const totalPages = retval.page_info.total_pages;
      if (totalPages == 1) {
        commit("setIsBusy", false);
      }
      if (!isServer) {
        dispatch("loadProducts", { search: search, totalPages: totalPages });
      }
    }

    return true;
  },

  async loadPartfinder({ commit, dispatch }) {
    commit("setProducts", []);
    dispatch("loadFiltersPathfinder");

    const retval = await getProductByPartfinder(1);
    commit("addProducts", retval.items);
    const totalPages = retval.page_info.total_pages;
    if (!isServer) {
      dispatch("loadProductsPathfinder", {
        totalPages: totalPages,
      });
    }

    return true;
  },
  async loadProductsPathfinder({ commit }, { totalPages }) {
    for (let page = 2; page < totalPages + 1; page++) {
      const retv = await getProductByPartfinder(page);
      commit("addProducts", retv.items);
    }
  },
  async loadFiltersPathfinder({ commit }) {
    const filters = await getProductFiltersByPartfinder().catch((e) => {
      Logger.error(
        "getProductFiltersBySearch",
        "category store actions load",
        e
      )();
      throw e;
    });
    const uFilters = [];
    filters.aggregations.forEach((element) => {
      if (element.attribute_code != "category_id") {
        if (element.attribute_code == "price") {
          element.min = parseInt(element.min_value);

          element.max = Math.ceil(element.max_value);

          commit("setPriceFilter", [element.min, element.max]);
        }
        uFilters.push(element);
      }
    });
    commit("setProductFilters", uFilters);
  },
  async loadPartfinderFilters({ commit }) {
    const filters = await getProductFiltersByPartfinder().catch((e) => {
      Logger.error(
        "getProductFiltersByPartfinder",
        "category store actions load",
        e
      )();
      throw e;
    });
    const uFilters = [];
    filters.aggregations.forEach((element) => {
      if (element.attribute_code != "category_id") {
        if (element.attribute_code == "price") {
          element.min = parseInt(element.min_value);

          element.max = Math.ceil(element.max_value);

          commit("setPriceFilter", [element.min, element.max]);
        }
        uFilters.push(element);
      }
    });
    commit("setProductFilters", uFilters);
  },
};

const mutations = {
  // always and only change vuex state through mutations.
  setCurrentCategory(state, data) {
    //Logger.debug("state", "setCurrentCategory", state)();
    //Logger.debug("data", "setCurrentCategory", data)();
    state.current = data;
    const breadcrumbs = data.breadcrumbs;
    let currentName = "undefined";
    if (typeof data.name == "string") {
      currentName = data.name;
    }
    const bcrumb = { current: currentName, routes: [] };
    if (breadcrumbs != null) {
      breadcrumbs.sort((a, b) => {
        if (a.category_level < b.category_level) {
          return -1;
        }
        if (a.category_level > b.category_level) {
          return 1;
        }
        return 0;
      });
      let path = "";
      breadcrumbs.forEach((element) => {
        if (path.length > 0) {
          path = path + "/";
        }
        path = path + element.category_url_key;
        let name = "undefined";
        if (typeof element.category_name == "string") {
          name = element.category_name;
        }
        const bc = {
          name: name,
          route_link: path,
        };
        bcrumb.routes.push(bc);
      });
    }
    //const bcrumb = { current: data.name, routes: routes };
    store.commit("breadcrumbs/set", bcrumb);
  },
  setProductFilters(state, data) {
    state.productFilters = data;
  },
  setActiveFilter(state, data) {
    state.activeFilter.push(data);
    store.commit("search/doFilterProducts");
  },
  setLoadProducts(state, data) {
    state.loadProducts = data;
  },

  resetActiveFilter(state) {
    state.activeFilter = [];
    store.commit("search/doFilterProducts");
  },
  setProducts(state, data) {
    state.products = data;
    state.ShowProducts = data;
    //store.commit("search/doFilterProducts");
  },
  addProducts(state, data) {
    data.forEach((element) => {
      const retval = state.products.find((o) => {
        if (o.sku == element.sku) {
          //Logger.debug(o.sku, "store category", element.sku)();

          return true;
        }
      });
      //Logger.debug("retval", "store category", retval)();
      if (retval == null) {
        state.products.push(element);
      }
      //Logger.debug("products count", "store category", state.products.length)();
    });
    //state.products = state.products.concat(data);
    store.commit("search/doFilterProducts");
  },
  setPriceFilter(state, data) {
    state.priceFilter = data;
    store.commit("search/doFilterProducts");
  },
  setPriceFilterMin(state,data){
    state.priceFilter[0] = data;
    store.commit("search/doFilterProducts");
  },
  setPriceFilterMax(state,data){
    state.priceFilter[1] = data;
    store.commit("search/doFilterProducts");
  },
  setSorting(state, data) {
    state.sorting = data;
    store.commit("search/doSorting");
  },
  addActiveFilter(state, data) {
    const obj = state.activeFilter.find((o) => {
      if (o.value == data.value) {
        return true; // stop searching
      }
    });
    if (obj == null) {
      state.activeFilter.push(data);
    }
    store.commit("search/doFilterProducts");
  },
  removeActiveFilter(state, data) {
    if (state.activeFilter.length > 0) {
      state.activeFilter.find((o, i) => {
        if (o.value == data.value) {
          state.activeFilter.splice(i, 1);
          return true;
        }
      });
      store.commit("search/doFilterProducts");
    }
  },
  setIsBusy(state, data) {
    state.isBusy = data;
  },
  doSorting(state) {
    if (state.sorting == "most_popular") {
      store.commit("search/doFilterProducts");
    } else {
      state.ShowProducts.sort((a, b) => {
        if (state.sorting == "bestsold") {
          if (a.sales_data.qty_ordered > b.sales_data.qty_ordered) {
            return -1;
          }
          if (a.sales_data.qty_ordered < b.sales_data.qty_ordered) {
            return 1;
          }
        }
        if (state.sorting == "name") {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
        } else if (state.sorting == "price") {
          if (
            a.price_range.minimum_price.final_price.value <
            b.price_range.minimum_price.final_price.value
          ) {
            return -1;
          }
          if (
            a.price_range.minimum_price.final_price.value >
            b.price_range.minimum_price.final_price.value
          ) {
            return 1;
          }
        } else if (state.sorting == "newest") {
          if (a.created_at < b.created_at) {
            return -1;
          }
          if (a.created_at > b.created_at) {
            return 1;
          }
        }
        return 0;
      });
    }
  },
  doFilterProducts(state) {
    //let products = state.products.map((a) => ({ ...a }));
    const filters = [];
    state.activeFilter.forEach((el) => {
      //Logger.debug("el", "filters", el)();
      if (filters.length > 0) {
        const obj = filters.find((o, i) => {
          if (o.attribute_code == el.attribute_code) {
            filters[i].values.push(el.label);
            return true;
          }
        });
        if (obj == null) {
          const values = [el.label];
          const filter = { attribute_code: el.attribute_code, values: values };
          filters.push(filter);
        }
      } else {
        const values = [el.label];
        const filter = { attribute_code: el.attribute_code, values: values };
        filters.push(filter);
      }
    });
    //Logger.debug("productfilter", "filters", filters)();
    if (state.products.length > 0) {
      const products = state.products.filter(function(item) {
        //filter first on price range
        if (
          item.price_range.minimum_price.final_price.value >=
            state.priceFilter[0] &&
          item.price_range.minimum_price.final_price.value <=
            state.priceFilter[1]
        ) {
          let doShow = true;
          //check if there are any filters
          if (filters.length > 0) {
            doShow = false;
            const isFound = [];
            //move trhough each filter
            //Logger.debug("length", "doFilter", filters.length)();

            filters.forEach((element, index) => {
              //Logger.debug("index", "doFilter", index)();

              isFound[index] = false;
              element.values.forEach((el) => {
                const retval = item.filter_attributes.find((o) => {
                  if (o.attribute_code == element.attribute_code) {
                    const found = o.values.find((v) => {
                      if (v.option_label == el) {
                        return true;
                      }
                    });
                    if (found != null) {
                      return true;
                    }
                  } else {
                    return false;
                  }
                });
                if (retval != null) {
                  isFound[index] = true;
                  return true;
                }
              });
            });
            //Logger.debug("isFound", "doFilter", isFound)();
            if (
              isFound.find((o) => {
                return o == false;
              }) == null
            ) {
              doShow = true;
            }
          }
          return doShow;
        } else {
          return false;
        }
      });
      state.ShowProducts = products;
    }
  },
};

const getters = {
  getCurrentCategory: (state) => state.current,
  getProductFilters: (state) => state.productFilters,
  getActiveFilter: (state) => state.activeFilter,
  getShowProducts: (state) => state.ShowProducts,
  getPriceFilter: (state) => state.priceFilter,
  getPriceFilterMin: (state) => state.priceFilter[0],
  getPriceFilterMax: (state) => state.priceFilter[1],
  getSorting: (state) => state.sorting,
  getLoadProducts: (state) => state.loadProducts,
  getIsBusy: (state) => state.isBusy,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
