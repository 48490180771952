//import { Logger } from "@storefront/core/lib/logger";
import config from "@config";

export function cleanPath(to) {
  let path = to.path;
  let split = to.path.split("/");
  if (config.languages.available.includes(split[1])) {
    if (split.length > 1) {
      split = split.splice(2);
      path = "/" + split.join("/");
    }
  }
  return path;
}
