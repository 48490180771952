import axios from "axios";
import config from "@config";
import graphql from "@graphql";

import { Logger } from "@storefront/core/lib/logger";
import { getCurrentLanguage } from "@storefront/core/i18n";

import coreCatagories from '@storefront/core/modules/catalog/store/category';


export default {

    namespaced: true,

    state() {
        return {
            ...coreCatagories.state(),
            superDealProducts: [],
        }
    },


    getters: {
        // ...coreCatagories.getters,
        getSuperDealProducts: (state) => state.superDealProducts

    },

    mutations: {

        // ...coreCatagories.mutations,

        addSuperDealProduct(state, data) {
            data.forEach((element) => {
                const retval = state.superDealProducts.find((o) => {
                    if (o.sku == element.sku) {
                        return true;
                    }
                });
                if (retval == null) {
                    state.superDealProducts.push(element);
                }
            });
        },

    },

    actions: {

        // ...coreCatagories.actions,
        async loadSuperDeal({ dispatch, commit }, categoryId) {

            const retval = await dispatch("getSuperDealProducts", {
                categoryId,
                page: 1,
            });
            commit("addSuperDealProduct", retval.items)

            const totalPages = retval.page_info.total_pages;
            dispatch("loadSuperDealProducts", {
                categoryId,
                totalPages,
            });


        },
        async loadSuperDealProducts({ commit, dispatch }, { categoryId, totalPages }) {
            for (let page = 2; page < totalPages + 1; page++) {
                const retv = await dispatch("getSuperDealProducts", {
                    categoryId,
                    page,
                });
                commit("addSuperDealProduct", retv.items);
            }
        },
        async getSuperDealProducts(_, { categoryId, page }) {
            const lang = getCurrentLanguage();
            //Needs to become variable based on language
            const storelang = config.languages[lang];
            const storeview = storelang["storeview"];

            const headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + config.shop.accessToken,
                Store: storeview,
            };
            let end_query = "";
            let query =
                " { products(pageSize: 36 currentPage: " +
                page +
                ' filter: { is_superdeal: true category_id: {eq: "' +
                categoryId +
                '"}';

            query = query + end_query + "})";
            query = query + graphql.queryFields.productOverview + "}";


            const retval = await axios({
                url: config.shop.graphQLURL + "?query=" + encodeURI(query),
                method: "GET",
                headers: headers,
            }).catch((e) => {
                Logger.error("getSuperDealProducts", "store category", e)();
                throw e;
            });

            return retval.data.data.products;
        }
    }



}