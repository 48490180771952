import {
  loadCategoryById,
  getBestSellers,
} from "@storefront/core/data-resolver/categories";
import {
  getProductFiltersByCategoryId,
  getProductByCategoryId,
} from "@storefront/core/data-resolver/products";
import { loadSliderByCategoryID } from "@storefront/core/data-resolver/sliders";
import store from "@/store";
import { Logger } from "@storefront/core/lib/logger";
import { isServer } from "@storefront/core/helpers";
const state = () => ({
  current: null,
  slider: null,
  menuCategories: [],
  productFilters: [],
  activeFilter: [],
  priceFilter: [0, 0],
  filteredCount: 0,
  products: [],
  ShowProducts: [],
  sorting: "default",
  bestSellers: {},
  bestSellersSimple: [],
  loadProducts: false,
  gtmProducts: [],
  ga4Products: [],
  gtmProductsCount: 0,
});

const actions = {
  /**
   * load category slider from magento
   *
   *
   */
  async loadSlider({ getters, commit }) {
    const cat = getters["getCurrentCategory"];
    const retv = await loadSliderByCategoryID(cat.id);
    commit("setSlider", retv);
  },
  /**
   * Load products by category id
   *
   *
   * @param {object} integer category, integer totalPages
   */
  async loadProducts({ getters, commit }, { category_id, totalPages }) {
    //const orgCat = getters["getCurrentCategory"];
    //const category = getters["getCurrentCategory"];
    commit("setLoadProducts", true);
    //const retval = await getProductByCategoryId(category.id, 2);
    //if (retval != null) {
    //commit("addProducts", retval.items);
    //const totalPages = retval.page_info.total_pages;
    for (let page = 2; page <= totalPages; page++) {
      //await new Promise((resolve) => setTimeout(resolve, 2000));
      const retv = await getProductByCategoryId(category_id, page);
      const loadProd = getters["getLoadProducts"];
      if (loadProd) {
        if (category_id == getters["getCurrentCategory"].id) {
          commit("addProducts", retv.items);
          let catStr = "";
          const bcrumbs = store.getters["breadcrumbs/getBreadcrumbsRoutes"];
          bcrumbs.forEach((bcrumb) => {
            catStr = catStr + bcrumb.name + "/";
          });
          const curr = store.getters["breadcrumbs/getBreadcrumbsCurrent"];
          catStr = catStr + curr;
          //gtm tracking info
          let gtmProds = [];
          let ga4Prods = [];
          retv.items.forEach((element) => {
            let gtmProd = {};
            let ga4Prod = {};
            gtmProd.id = element.sku;
            gtmProd.name = element.name;
            gtmProd.list = "Category";
            gtmProd.category = catStr;
            commit("setGtmProductsCount");
            gtmProd.position = getters["getGtmProductsCount"];
            gtmProds.push(gtmProd);
            ga4Prod.id = element.sku;
            ga4Prod.name = element.name;
            ga4Prod.price = element.price_range.maximum_price.final_price.value;
            if (element.price_range.maximum_price.discount.amount_off > 0) {
              ga4Prod.discount = element.price_range.maximum_price.discount.amount_off;
            }
            ga4Prod.position = getters["getGtmProductsCount"];
            ga4Prods.push(ga4Prod);
          });
          commit("setGtmProducts", gtmProds);
        } else {
          break;
        }
      } else {
        break;
      }
    }
    //}
  },
  /**
   * load bestsellers based on current category
   *
   */
  async loadBestSellers({ getters, commit }) {
    const category = getters["getCurrentCategory"];
    const retval = await getBestSellers(category.id);
    if (retval != false) {
      commit("setBestSellers", retval);
      const simpleBestSellers = [];
      retval.tabs.forEach((element) => {
        element.items.forEach((elm) => {
          simpleBestSellers.push(elm);
        });
      });
      commit("setBestSellersSimple", simpleBestSellers);
    }
  },
  /**
   * load product filters based on id of the category
   *
   * @param {object} integer id
   */
  async loadFilters({ commit, getters, dispatch }, { id = 0 }) {
    const filters = await getProductFiltersByCategoryId(id).catch((e) => {
      Logger.error(
        "getProductFiltersByCategoryId",
        "category store actions load",
        e
      )();
      throw e;
    });
    commit("addProducts", filters.items);
    commit("resetGtmProductsCount");
    let catStr = "";
    const bcrumbs = store.getters["breadcrumbs/getBreadcrumbsRoutes"];
    bcrumbs.forEach((bcrumb) => {
      catStr = catStr + bcrumb.name + "/";
    });
    const curr = store.getters["breadcrumbs/getBreadcrumbsCurrent"];
    catStr = catStr + curr;
    //gtm tracking info
    let gtmProds = [];
    let ga4Prods = [];
    filters.items.forEach((element) => {
      let gtmProd = {};
      let ga4Prod = {}
      gtmProd.id = element.sku;
      gtmProd.name = element.name;
      gtmProd.list = "Category";
      gtmProd.category = catStr;
      commit("setGtmProductsCount");
      gtmProd.position = getters["getGtmProductsCount"];
      gtmProds.push(gtmProd);
      ga4Prod.id = element.sku;
      ga4Prod.name = element.name;
      ga4Prod.price = element.price_range.maximum_price.final_price.value;
      if (element.price_range.maximum_price.discount.amount_off > 0) {
        ga4Prod.discount = element.price_range.maximum_price.discount.amount_off;
      }
      ga4Prod.position = getters["getGtmProductsCount"];
      ga4Prods.push(ga4Prod);
    });
    commit("setGtmProducts", gtmProds);
    commit("setGa4Products", ga4Prods);

    dispatch("loadProducts", {
      category_id: id,
      totalPages: filters.page_info.total_pages,
    });
    const uFilters = [];
    filters.aggregations.forEach((element) => {
      if (element.attribute_code != "category_id") {
        if (element.attribute_code == "price") {
          if (parseInt(element.min_value) < 0) {
            element.min_value = 0;
          }
          element.min = parseInt(element.min_value);
          if (Math.ceil(element.max_value) < element.min_value) {
            element.max = element.min_value;
          } else {
            element.max = Math.ceil(element.max_value);
          }
          if (element.min < 0) {
            element.min = 0;
          }
          if (element.max < 0) {
            element.max = 0;
          }
          commit("setPriceFilter", [element.min, element.max]);
        }
        uFilters.push(element);
      }
    });
    commit("setProductFilters", uFilters);
  },

  /**
   * load the category
   *
   * @param {object} integer id
   * @returns category object
   * @deprecated replaced by setCategory dispatch because of the route update
   */
  async load({ commit, dispatch }, { id = 0 }) {
    commit("setLoadProducts", false);
    commit("setBestSellersSimple", []);
    commit("setBestSellers", []);
    const category = await loadCategoryById(id).catch((e) => {
      Logger.error("loadCategoryById", "category store actions load", e)();
      throw e;
    });
    if (category == null) {
      commit("setProductFilters", {});
      return false;
    } else {
      if (category.description) {
        category.description = category.description.replace(
          /<a href=/g,
          "<b-link to="
        );
        category.description = category.description.replace(/<a/g, "<b-link");
        category.description = category.description.replace(
          /<\/a>/g,
          "</b-link>"
        );
        category.description = category.description.replace(
          / href="\//g,
          ' to="/'
        );
        category.description = category.description.replace(
          /<b-link((?!<\/b-link>).)to="(http|mailto|tel)(.*?)<\/b-link>/g,
          '<a$1href="$2$3</a>'
        );
      }

      commit("setCurrentCategory", category);
      dispatch("loadSlider");
      if (category.is_anchor == 1) {
        commit("setProducts", []);
        commit("setProductFilters", {});
        commit("resetActiveFilter");
        dispatch("loadFilters", { id: id });
        if (!isServer) {
          dispatch("loadBestSellers");
        }
      } else {
        commit("setProductFilters", {});
        commit("resetActiveFilter");
        commit("setPriceFilter", [0, 0]);
        commit("setBestSellersSimple", []);
      }
      return category;
    }
  },
  /**
   * load the category
   *
   * @param {object} integer id
   * @returns category object
   * @deprecated replaced by setCategory dispatch because of the route update
   */
  async setCategory({ commit, dispatch }, { category }) {
    commit("setLoadProducts", false);
    commit("setBestSellersSimple", []);
    commit("setBestSellers", []);

    if (category == null) {
      commit("setProductFilters", {});
      return false;
    } else {
      if (category.description) {
        category.description = category.description.replace(
          /<a href=/g,
          "<b-link to="
        );
        category.description = category.description.replace(/<a/g, "<b-link");
        category.description = category.description.replace(
          /<\/a>/g,
          "</b-link>"
        );
        category.description = category.description.replace(
          / href="\//g,
          ' to="/'
        );
        category.description = category.description.replace(
          /<b-link((?!<\/b-link>).)to="(http|mailto|tel)(.*?)<\/b-link>/g,
          '<a$1href="$2$3</a>'
        );
      }

      commit("setCurrentCategory", category);
      dispatch("loadSlider");
      if (category.is_anchor == 1) {
        commit("setProducts", []);
        commit("setProductFilters", {});
        commit("resetActiveFilter");
        dispatch("loadFilters", { id: category.id });
        if (!isServer) {
          dispatch("loadBestSellers");
        }
      } else {
        commit("setProductFilters", {});
        commit("resetActiveFilter");
        commit("setPriceFilter", [0, 0]);
        commit("setBestSellersSimple", []);
      }
    }
  },
};

const mutations = {
  // always and only change vuex state through mutations.
  /**
   * set current category
   *
   * @param {object} state
   * @param {object} data
   * @private
   *
   */
  setCurrentCategory(state, data) {
    state.current = data;
    const breadcrumbs = data.breadcrumbs;
    let currentName = "undefined";
    if (typeof data.name == "string") {
      currentName = data.name;
    }
    const bcrumb = { current: currentName, routes: [] };
    if (breadcrumbs != null) {
      breadcrumbs.sort((a, b) => {
        if (a.category_level < b.category_level) {
          return -1;
        }
        if (a.category_level > b.category_level) {
          return 1;
        }
        return 0;
      });
      let path = "";
      breadcrumbs.forEach((element) => {
        if (path.length > 0) {
          path = path + "/";
        }
        path = path + element.category_url_key;
        let name = "undefined";
        if (typeof element.category_name == "string") {
          name = element.category_name;
        }
        const bc = {
          name: name,
          route_link: "/" + path,
        };
        bcrumb.routes.push(bc);
      });
    }
    //const bcrumb = { current: data.name, routes: routes };
    store.commit("breadcrumbs/set", bcrumb);
  },
  /**
   * set product filters
   *
   * @param {object} state
   * @param {object} data
   * @private
   *
   */
  setProductFilters(state, data) {
    state.productFilters = data;
  },
  /**
   * set category slider
   *
   * @param {object} state
   * @param {object} data
   * @private
   *
   */
  setSlider(state, data) {
    state.slider = data;
  },
  /**
   * set load products
   *
   * @param {object} state
   * @param {array} data
   * @private
   *
   */
  setLoadProducts(state, data) {
    state.loadProducts = data;
  },
  /**
   * set active filter
   *
   * @param {object} state
   * @param {object} data
   */
  setActiveFilter(state, data) {
    state.activeFilter.push(data);
    store.commit("category/doFilterProducts");
  },
  /**
   * reset active filter
   *
   * @param {object} state
   */
  resetActiveFilter(state) {
    state.activeFilter = [];
    store.commit("category/doFilterProducts");
  },
  /**
   * set products
   *
   * @param {object} state
   * @param {array} data
   * @private
   */
  setProducts(state, data) {
    state.products = data;
    state.ShowProducts = data;
    //store.commit("category/doFilterProducts");
  },
  /**
   * add products
   *
   * @param {object} state
   * @param {array} data
   * @private
   */
  addProducts(state, data) {
    data.forEach((element) => {
      const retval = state.products.find((o) => {
        if (o.sku == element.sku) {
          return true;
        }
      });
      if (retval == null) {
        state.products.push(element);
      }
    });
    store.commit("category/doFilterProducts");
  },
  /**
   * set price filter
   *
   * @param {object} state
   * @param {object} data
   */
  setPriceFilter(state, data) {
    state.priceFilter = data;
    store.commit("category/doFilterProducts");
  },
  /**
   * set bestsellers
   *
   * @param {object} state
   * @param {array} data
   * @private
   */
  setBestSellers(state, data) {
    state.bestSellers = data;
  },
  /**
   * set bestsellers
   *
   * @param {object} state
   * @param {array} data
   * @private
   */
  setGtmProducts(state, data) {
    state.gtmProducts = data;
  },
  /**
 * set Google Analytics 4 product list
 *
 * @param {object} state
 * @param {array} data
 * @private
 */
  setGa4Products(state, data) {
    state.ga4Products = data;
  },
  /**
   * set bestsellers
   *
   * @param {object} state
   * @param {array} data
   * @private
   */
  setGtmProductsCount(state) {
    state.gtmProductsCount = state.gtmProductsCount + 1;
    return state.gtmProductsCount;
  },

  resetGtmProductsCount(state) {
    state.gtmProductsCount = 0;
  },
  /**
   * set simple bestsellers
   *
   * @param {object} state
   * @param {array} data
   * @private
   */
  setBestSellersSimple(state, data) {
    state.bestSellersSimple = data;
  },
  /**
   * set sorting
   *
   * @param {object} state
   * @param {array} data
   */
  setSorting(state, data) {
    state.sorting = data;
    store.commit("category/doSorting");
  },
  /**
   * add active filter
   *
   * @param {object} state
   * @param {object} data
   */
  addActiveFilter(state, data) {
    const obj = state.activeFilter.find((o) => {
      if (o.value == data.value) {
        return true; // stop searching
      }
    });
    if (obj == null) {
      state.activeFilter.push(data);
    }
    store.commit("category/doFilterProducts");
  },

  /**
   * remove active filter
   *
   * @param {object} state
   * @param {object} data
   */
  removeActiveFilter(state, data) {
    if (state.activeFilter.length > 0) {
      state.activeFilter.find((o, i) => {
        if (o.value == data.value) {
          state.activeFilter.splice(i, 1);
          return true;
        }
      });
      store.commit("category/doFilterProducts");
    }
  },
  /**
   * do sorting
   * @param {object} state
   * @private
   */
  doSorting(state) {
    if (state.sorting == "default") {
      store.commit("category/doFilterProducts");
    } else {
      state.ShowProducts.sort((a, b) => {

        if (state.sorting == "bestsold") {
          if (a.sales_data.qty_ordered > b.sales_data.qty_ordered) {
            return -1;
          }
          if (a.sales_data.qty_ordered < b.sales_data.qty_ordered) {
            return 1;
          }
        } else if (state.sorting == "name_up") {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
        } else if (state.sorting == "name_down") {
          if (a.name > b.name) {
            return -1;
          }
          if (a.name < b.name) {
            return 1;
          }
        } else if (state.sorting == "name") {
          if (a.name > b.name) {
            return -1;
          }
          if (a.name < b.name) {
            return 1;
          }
        } else if (state.sorting == "price_up") {
          if (
            a.price_range.minimum_price.final_price.value <
            b.price_range.minimum_price.final_price.value
          ) {
            return -1;
          }
          if (
            a.price_range.minimum_price.final_price.value >
            b.price_range.minimum_price.final_price.value
          ) {
            return 1;
          }
        } else if (state.sorting == "price") {
          if (
            a.price_range.minimum_price.final_price.value <
            b.price_range.minimum_price.final_price.value
          ) {
            return -1;
          }
          if (
            a.price_range.minimum_price.final_price.value >
            b.price_range.minimum_price.final_price.value
          ) {
            return 1;
          }
        } else if (state.sorting == "price_down") {
          if (
            a.price_range.minimum_price.final_price.value >
            b.price_range.minimum_price.final_price.value
          ) {
            return -1;
          }
          if (
            a.price_range.minimum_price.final_price.value <
            b.price_range.minimum_price.final_price.value
          ) {
            return 1;
          }
        } else if (state.sorting == "newest") {
          if (a.created_at < b.created_at) {
            return -1;
          }
          if (a.created_at > b.created_at) {
            return 1;
          }
        }
        return 0;
      });
    }
  },
  /**
   * do filter product
   * @param {object} state
   * @private
   */
  doFilterProducts(state) {
    //let products = state.products.map((a) => ({ ...a }));
    const filters = [];
    state.activeFilter.forEach((el) => {
      if (filters.length > 0) {
        const obj = filters.find((o, i) => {
          if (o.attribute_code == el.attribute_code) {
            filters[i].values.push(el.label);
            return true;
          }
        });
        if (obj == null) {
          const values = [el.label];
          const filter = { attribute_code: el.attribute_code, values: values };
          filters.push(filter);
        }
      } else {
        const values = [el.label];
        const filter = { attribute_code: el.attribute_code, values: values };
        filters.push(filter);
      }
    });
    if (state.products.length > 0) {
      const products = state.products.filter(function (item) {
        //filter first on price range
        if (
          item.price_range.minimum_price.final_price.value >=
          state.priceFilter[0] &&
          item.price_range.minimum_price.final_price.value <=
          state.priceFilter[1]
        ) {
          let doShow = true;
          //check if there are any filters
          if (filters.length > 0) {
            doShow = false;
            const isFound = [];
            //move trhough each filter
            filters.forEach((element, index) => {
              isFound[index] = false;
              element.values.forEach((el) => {
                const retval = item.filter_attributes.find((o) => {
                  if (o.attribute_code == element.attribute_code) {
                    const found = o.values.find((v) => {
                      if (v.option_label == el) {
                        return true;
                      }
                    });
                    if (found != null) {
                      return true;
                    }
                  } else {
                    return false;
                  }
                });
                if (retval != null) {
                  isFound[index] = true;
                  return true;
                }
              });
            });
            if (
              isFound.find((o) => {
                return o == false;
              }) == null
            ) {
              doShow = true;
            }
          }
          return doShow;
        } else {
          return false;
        }
      });
      state.ShowProducts = products;
    }
  },
};

const getters = {
  getCurrentCategory: (state) => state.current,
  getProductFilters: (state) => state.productFilters,
  getActiveFilter: (state) => state.activeFilter,
  getLoadProducts: (state) => state.loadProducts,
  getShowProducts: (state) => state.ShowProducts,
  getPriceFilter: (state) => state.priceFilter,
  getSorting: (state) => state.sorting,
  getBestSellersSimple: (state) => state.bestSellersSimple,
  getBestSellers: (state) => state.bestSellers,
  getSlider: (state) => state.slider,
  getGtmProducts: (state) => state.gtmProducts,
  getGa4Products: (state) => state.ga4Products,
  getGtmProductsCount: (state) => state.gtmProductsCount,
  getCurrentCategoryId: (state) => {
    if (state.current != null) {
      return state.current.id;
    } else {
      return 0;
    }
  },
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
